import React, { useState, useEffect, useRef } from "react";
import csrfToken from "../src/csrf_token";
import trackProductTileClick from "../utils/fresh-relevance/trackProductTileClick";

const RECOMMENDATIONS_PATH = "/api/v1/fr_products";

const FreshRelevanceRecommendations = ({
  slot,
  partial,
  fallbackPartial,
  fallbackTimeout,
}) => {
  const [recommendationsHtml, setRecommendationsHtml] = useState(null);
  const [usingFallback, setUsingFallback] = useState(false);
  const parsedTimeout = isNaN(parseInt(fallbackTimeout))
    ? null
    : parseInt(fallbackTimeout);
  const recommendationsRef = useRef(null);
  const recommendationsContainer = recommendationsRef.current;

  const fetchRecommendations = async () => {
    try {
      const params = new URLSearchParams({ slot: slot, partial: partial });
      const fullPath = `${RECOMMENDATIONS_PATH}?${params}`;
      const opts = {
        method: "GET",
        headers: {
          "X-CSRF-Token": csrfToken(),
          "Content-Type": "text/html",
        },
      };
      if (parsedTimeout) opts.signal = AbortSignal.timeout(parsedTimeout);

      const response = await fetch(fullPath, opts);

      if (!response.ok) {
        setRecommendationsHtml(() => fallbackPartial);
        setUsingFallback(() => true);
      } else {
        const data = await response.text();
        setRecommendationsHtml(() => data);
      }
    } catch (_error) {
      setRecommendationsHtml(() => fallbackPartial);
      setUsingFallback(() => true);
    }
  };

  useEffect(() => fetchRecommendations(), []);

  useEffect(() => {
    if (ReactRailsUJS) {
      ReactRailsUJS.mountComponents();

      if (recommendationsContainer && !usingFallback) {
        recommendationsContainer
          .querySelectorAll(".producttile-title > a, .producttile-picture > a")
          .forEach((a) =>
            a.addEventListener("click", trackProductTileClick)
          );
      }
    }

    return () => {
      if (recommendationsContainer && !usingFallback) {
        recommendationsContainer
          .querySelectorAll(".producttile-title > a, .producttile-picture > a")
          .forEach((a) =>
            a.removeEventListener("click", trackProductTileClick)
          );
      }
    };
  }, [recommendationsHtml, recommendationsContainer, usingFallback]);

  return (
    <>
      {recommendationsHtml && (
        <div
          ref={recommendationsRef}
          dangerouslySetInnerHTML={{ __html: recommendationsHtml }}
        ></div>
      )}
    </>
  );
};

export default FreshRelevanceRecommendations;
